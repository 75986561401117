import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { posthog } from 'posthog-js'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'

import { POSTHOG_API_HOST, POSTHOG_API_KEY, SENTRY_URL } from '~/lib/constants'

Sentry.init({
  dsn: SENTRY_URL,
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    // https://github.com/getsentry/sentry-javascript/issues/9728 eslint bug
    // eslint-disable-next-line import/namespace
    Sentry.replayIntegration(),
  ],
})

function PosthogInit() {
  useEffect(() => {
    posthog.init(POSTHOG_API_KEY, {
      api_host: POSTHOG_API_HOST,
    })
  }, [])

  return null
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
      <PosthogInit />
    </StrictMode>,
  )
})
