export const POSTHOG_API_KEY =
  process.env.NODE_ENV === 'development'
    ? 'fake_key'
    : 'phc_ehFMOlLuvs6m1jK41z6NGkGnnv0DPPrVnheAgpb1bVL'
export const POSTHOG_API_HOST =
  process.env.NODE_ENV === 'development'
    ? 'https://www.posthog.com'
    : 'https://us.i.posthog.com'

export const SENTRY_URL =
  process.env.NODE_ENV === 'development'
    ? ''
    : 'https://157758fe49475b8c472a62c50554e633@o932477.ingest.us.sentry.io/4507407537537024'
